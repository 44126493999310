import React, { useContext, useEffect, useState } from 'react';
import { common, FilterCollection } from 'plato-js-client';
import {
  Button,
  CustomInput,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table } from 'reactstrap';
import Icon from './Icon';
import { addTab } from '../Actions';
import { Store } from "../Store";
import moment from 'moment';
import { successToast, errorToast } from './Toasties';

export default function DeleteTemplateJobsModal(props) {

  const {
    dispatch
  } = useContext(Store);

  const [jobs, setJobs] = useState();
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [toggleDeleteBtn, setToggleDeleteBtn] = useState(true);
  const [deletedRows, setDeletedRows] = useState([]);
  const [initialSelected, setInitialSelected] = useState(null);

  useEffect(() => {
    const getJobs = async () => {
      try {
        setLoading(true);
        setToggleDeleteBtn(false);

        let jobs = new FilterCollection({
          path: 'workorder',
          object: common.WorkOrder,
        });

        jobs.limit = 999;
        jobs.page = 1;
        jobs.fields = 'id:preferredstartdatetime:status';
        jobs.addFilters({
          type: 'Instance',
          templateid: props.template.id,
          status: 'New|Started|Completed',
          preferredstartdatetime: '>' + moment().format('YYYY-MM-DDT00.00.00')
        });

        await jobs.fetch();

        // Apply the additional filter here
        const filteredJobs = jobs.collection.filter(job =>
          job.status === "New" ||
          job.status === "Started" ||
          (job.status === "Completed" && (
            !job.substatus.id ||
            (job.substatus.workordersubstatus.substatusname.toLowerCase() === 'job practically completed')
          ))
        );

        setJobs(filteredJobs);
      } catch (error) {
        errorToast(`Error fetching jobs: ${error.message}`);
      } finally {
        setLoading(false);
        setToggleDeleteBtn(true);
      }
    };

    getJobs();
  }, [props.template.id]);

  const toggleSelected = (jobId, checked) => {
    let newSelected = [...selected];

    if (checked) {
        const jobIndex = jobs.findIndex(j => j.id === jobId);

        // If this is the first selection, store it as initialSelected
        if (selected.length === 0) {
            setInitialSelected(jobId);
        }

        // Always select the current jobId
        if (!newSelected.includes(jobId)) {
            newSelected.push(jobId);
        }

        // Select all jobs with later dates
        for (let i = jobIndex + 1; i < jobs.length; i++) {
            if (!newSelected.includes(jobs[i].id)) {
                newSelected.push(jobs[i].id);
            }
        }

        // If selecting a job earlier than the initial selected job, set it as the new initial selected job and lock all jobs after it
        const initialSelectedIndex = jobs.findIndex(j => j.id === initialSelected);
        if (jobIndex < initialSelectedIndex) {
            setInitialSelected(jobId);
            for (let i = jobIndex + 1; i < jobs.length; i++) {
                if (!newSelected.includes(jobs[i].id)) {
                    newSelected.push(jobs[i].id);
                }
            }
        }
    } else {
        const jobIndex = jobs.findIndex(j => j.id === jobId);
        const initialSelectedIndex = jobs.findIndex(j => j.id === initialSelected);

        // Allow unselecting the initial selection or any job earlier than the initial selection
        if (jobId === initialSelected || jobIndex < initialSelectedIndex) {
            // Unselect the current jobId
            newSelected = newSelected.filter(id => id !== jobId);

            // Unselect all jobs with later dates
            for (let i = jobIndex + 1; i < jobs.length; i++) {
                newSelected = newSelected.filter(id => id !== jobs[i].id);
            }

            // If the initial selected job is unselected, clear the initialSelected
            if (jobId === initialSelected) {
                setInitialSelected(null);
            }
        }
    }

    setSelected(newSelected);
  };

  const selectAll = () => {
    setSelected(jobs.map(j => j.id));
  };

  const deselectAll = () => {
    setSelected([]);
  };

  const deleteJobs = async () => {
    if (window.confirm('Do you want to proceed?')) {
      //disable the delete button
      setToggleDeleteBtn(false);

    try {
      //delete the workorders one by one
      for (const id of selected) {
        //'disable' the row to say that one is done
        deletedRows.push(id);
        setDeletedRows(deletedRows);
        let workOrderJob = new common.WorkOrder(id);

          // Delete the Job item
          await workOrderJob.delete();
      }

        successToast('Jobs deleted successfully!');
      } catch (error) {
        errorToast(`Error deleting the job: ${error.message}`);
      }
      props.toggle();
    }
  }

  return (
    <Modal isOpen toggle={props.toggle} size="lg">
      <ModalHeader toggle={props.toggle}>Update jobs</ModalHeader>
      <ModalBody>
        <p>
          Any outstanding jobs deriving from this template can now be deleted below.
          Once deleted, edit the template and when saved new jobs will be created automatically with the updated template information.
        </p>
        <div className="mb-2 clearfix">
          <Button onClick={deselectAll} className="float-right ml-2" disabled={selected.length === 0}>deselect all</Button>
          <Button onClick={selectAll} className="float-right" disabled={!jobs || jobs.length === 0}>select all</Button>
        </div>
        <Table size="sm" className="">
          <thead>
            <tr>
              <th>id</th>
              <th>start</th>
              <th>status</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {!(jobs && jobs.length > 0) &&
            <tr>
              {loading ?
              <td colSpan="4">Loading...</td>
              :
              <td colSpan="4">There are no outstanding jobs to delete.</td>
              }
            </tr>
            }
            {jobs && jobs.length > 0 && jobs.map(job => {
              return (
                //change the colour of the row once processed
                <tr key={job.id} style={deletedRows.indexOf(job.id) !== -1
                    ? { background: 'grey' }
                    : {  }
                }>
                  <td>{job.id}</td>
                  <td>{moment(job.preferredstartdatetime).format('DD/MM/YY HH:mm')}</td>
                  <td>{job.status + (job.substatus.id ? ' - ' + job.substatus.workordersubstatus.substatusname : '')}</td>
                  <td className="align-middle text-center">
                    <Icon
                      icon="pencil-alt"
                      onClick={() => {
                        addTab('editJob', {workOrderId: job.id}, dispatch);
                        props.toggle();
                      }}
                    />{' '}
                    <CustomInput
                      className="d-inline"
                      type="switch"
                      id={"sw" + job.id}
                      checked={selected.includes(job.id)}
                      onChange={e => toggleSelected(job.id, e.target.checked)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        {selected.length > 0 &&
        <Button
          color="secondary"
          onClick={deleteJobs}
          disabled={!toggleDeleteBtn}
        >
          Apply to {selected.length} job(s)
        </Button>
        }
      </ModalFooter>
    </Modal>
  );
}
